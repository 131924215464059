export const initialAuthState = () => {
  const $storage = JSON.parse(localStorage.getItem("fighterodds"));

  if ($storage && $storage.accessToken) {
    return {
      isLoggedIn: true,
      accessToken: $storage.accessToken,
      refreshToken: $storage.refreshToken,
      expirationTime: $storage.expirationTime,
      userId: $storage.userId
    };
  }

  return {
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    expirationTime: null,
    userId: null
  };
};
