import { createSlice } from "@reduxjs/toolkit";
import { initialAuthState } from "../helpers/initialAuthState";

const initialState = initialAuthState();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin(state, action) {
      const $storage = {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expirationTime: action.payload.expirationTime,
        userId: action.payload.userId
      };

      state.accessToken = $storage.accessToken;
      state.refreshToken = $storage.refreshToken;
      state.expirationTime = $storage.expirationTime;
      state.isLoggedIn = true;
      state.userId = $storage.userId;
      localStorage.setItem("fighterodds", JSON.stringify($storage));
    },

    signup(state, action) {
      
    },

    refresh(state, action) {
      const $storage = {
        accessToken: action.payload.accessToken,
        expirationTime: action.payload.expirationTime,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
      };

      state.accessToken = $storage.accessToken;
      state.refreshToken = $storage.refreshToken;
      state.expirationTime = $storage.expirationTime;
      state.isLoggedIn = true;
      state.userId = $storage.userId;
      localStorage.setItem("fighterodds", JSON.stringify($storage));
    },

    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.expirationTime = null;
      state.isLoggedIn = false;
      state.userId = null;
      localStorage.removeItem("fighterodds");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
