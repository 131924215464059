import { createSlice } from "@reduxjs/toolkit";

const totalsSlice = createSlice({
  name: "totals",
  initialState: {
    current: {},
    totals: {}
  },
  reducers: {
    // TODO: fare metodi qui
  },
});

export const totalsctions = totalsSlice.actions;

export default totalsSlice.reducer;
