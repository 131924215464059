import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { firebase } from "./services/firebase";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Main CSS
import "./App.scss";

const Signin = React.lazy(() => import("./pages/Signin"));
// const Signup = React.lazy(() => import("./pages/Signup"));
const RecoverPassword = React.lazy(() => import("./pages/RecoverPassword"));
const Fights = React.lazy(() => import("./pages/Fights"));
const Totals = React.lazy(() => import("./pages/Totals"));
const Stats = React.lazy(() => import("./pages/Stats"));
const Perfomance = React.lazy(() => import("./pages/Perfomance"));

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) firebase.initialize();
  
    return () => {
      isMounted = false;
    }
  })
  

  return (
    <>
      <Routes>
        {/* SIGNIN ROUTE */}
        <Route
          path="/signin"
          exact
          element={!isLoggedIn ? <Signin /> : <Navigate to="/fights" />}
        />
        {/* SIGNUP ROUTE */}
        {/* <Route
          path="/signup"
          exact
          element={!isLoggedIn ? <Signup /> : <Navigate to="/fights" />}
        /> */}
        {/* RECOVER PASSWORD ROUTE */}
        <Route
          path="/recover"
          exact
          element={
            !isLoggedIn ? <RecoverPassword /> : <Navigate to="/fights" />
          }
        />
        {/* FIGHTS ROUTE */}
        <Route
          path="/fights"
          exact
          element={isLoggedIn ? <Fights /> : <Navigate to="/signin" />}
        />
        {/* TOTALS ROUTE */}
        <Route
          path="/totals"
          exact
          element={isLoggedIn ? <Totals /> : <Navigate to="/signin" />}
        />
        {/* STATS ROUTE */}
        <Route
          path="/analytics"
          exact
          element={isLoggedIn ? <Stats /> : <Navigate to="/signin" />}
        />
        {/* PERFOMANCE ROUTE */}
        <Route
          path="/perfomance"
          exact
          element={isLoggedIn ? <Perfomance /> : <Navigate to="/signin" />}
        />
        {/* 404 PAGE */}
        <Route path="*" element={<Navigate to="/fights" />} />
      </Routes>
    </>
  );
}

export default App;
