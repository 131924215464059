const LoadingSpinner = () => {
  return (
    <div
      className="spinner-border text-dark"
      role="status"
      style={{width:"4rem", height:"4rem"}}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
