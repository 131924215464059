import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth";
import totalsSlice from "./totals";

const store = configureStore({
  reducer: {
    auth: authSlice,
    totals: totalsSlice,
  },
});

export default store;
