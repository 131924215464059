import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  setDoc,
  getDoc,
  doc,
  deleteField,
  updateDoc,
  getDocs,
} from "firebase/firestore";

let app = null;

export const firebase = {
  initialize: () => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
      appId: process.env.REACT_APP_FIREBASE_APPID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
    };

    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  },

  signup: ({ email, password }) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth(app);

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          resolve(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          reject({
            errorCode,
            errorMessage,
          });
        });
    });
  },

  signin: ({ email, password }) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth(app);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          resolve(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          reject({
            errorCode,
            errorMessage,
          });
        });
    });
  },

  refresh: () => {
    return new Promise((resolve, reject) => {
      //firebase.initialize();
      const auth = getAuth(app);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          //const token = await getIdToken(user);
          //console.log("getIdToken(): ", token);
          resolve(user);
        } else {
          reject(user);
        }
      });
    });
  },

  users: {
    add: () => {},
    update: () => {},
    remove: () => {},
  },

  totals: {
    /**
     * Get user's totals list.
     * @param {string} param0
     */
    get: ({ user }) => {
      const db = getFirestore(app);

      return new Promise(async (resolve, reject) => {
        try {
          const docRef = doc(db, "totals", user.toString());
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            resolve(docSnap.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            resolve([]);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    /**
     * Save total in relative user collection.
     * @param {object} total
     * @param {string} userId
     * @returns {any}
     */
    save: (data, userId) => {
      let editMode = false;
      const db = getFirestore(app);

      return new Promise(async (resolve, reject) => {
        try {
          const totalsCollection = collection(db, "totals");
          const querySnapshot = await getDocs(totalsCollection);
          let previous_picks = [];

          querySnapshot.forEach((doc) => {
            if (doc.id === userId) {
              editMode = true;
              if (doc.data()[data.matchId]) {
                previous_picks = doc.data()[data.matchId].picks;
              }
            }
          });

          const docData = {};

          docData[data.matchId] = {
            commenceTime: data.commenceTime,
            matchId: data.matchId,
            winner: data.winner,
            opponent: data.opponent,
            picks: [...previous_picks, data.pick],
          };

          if (!editMode) {
            await setDoc(doc(db, "totals", userId.toString()), docData);
          } else {
            await updateDoc(doc(db, "totals", userId.toString()), docData);
          }

          console.log(">> Saving in your Totals <<");
          resolve("ok");
          //console.log("Document written with ID: ", docRef);
        } catch (e) {
          console.error("Error adding document: ", e);
          reject(e);
        }
      });
    },

    /**
     * Remove single pick from user's totals.
     * @param {object} data
     * @param {string} userId
     */
    remove: (pick_id, match_id, userId) => {
      const db = getFirestore(app);

      return new Promise(async (resolve, reject) => {
        try {
          const docRef = doc(db, "totals", userId.toString());
          const docSnap = await getDoc(docRef);
          const data = docSnap.data();
          const matchData = data[match_id];

          let filteredPicks = [];

          filteredPicks = matchData.picks.filter((value, index, arr) => {
            return value.id !== pick_id;
          });

          if (filteredPicks.length <= 0) {
            await updateDoc(docRef, {
              [match_id]: deleteField(),
            });
          } else {
            await updateDoc(docRef, {
              [match_id]: {
                ...matchData,
                picks: filteredPicks,
              },
            });
          }

          resolve("ok");
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },

    removeAll: async () => {},
  },
};
